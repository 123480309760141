import React, { useRef, useState } from "react"

import styled from "styled-components"

import { useContactData } from "../hooks/useContactData";

import ThemeDefault from "../theme/ThemeDefault";

import { GatsbyImage } from "gatsby-plugin-image"
import {device} from "../theme/MediaQueries"
import ParseHTML, { ParseAndRemoveTags } from "../helpers/ParseHTML";

import HubspotForms from "../Hubspot/HubspotForms";

import Facebook from '../../assets/images/icons/social-facebook.svg'
import Instagram from '../../assets/images/icons/social-instagram.svg'
import LinkedIn from '../../assets/images/icons/social-linkedin.svg'
import Email from '../../assets/images/icons/social-email.svg'
import Twitter from '../../assets/images/icons/social-twitter.svg'

import ZendeskForm from "./ZendeskForm";
import Spinner from "../elements/Spinner";

const HubspotFormSection = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};
  margin-bottom: 64px;

  .form-col {
    margin: 0 auto;
    width: 90% !important;
    max-width: 600px !important;
    display: block !important;
  }

  .inner-div {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;

      & .title-col {
        align-items: center;
      }
      & h1  {
        text-align: center;
        & *  {
          text-align: center;
        }
      }
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.2;
      letter-spacing: -1px;
      margin-bottom: 32px;
      text-align: left;
      font-weight: 500;
      
      p {
        margin-bottom: 20px;
      }
    }
    
    .form-col {
      margin: 0 auto;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: stretch;
      text-align: center;
      width: 100%;

      .form-wrap {
        margin: 0 auto;
      }

      .form-title {
        font-size:  ${ThemeDefault.font_4};
        font-family: ${ThemeDefault.fontPrimary};
        line-height: 1.4;
        color: ${ThemeDefault.white}
      }
      
    }

    .hero-text {
      text-align: left;
      margin-bottom: 32px;
      font-size: var(--font-2);
      padding-right: 64px;

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }
  }

  .points {
    margin-bottom: 32px;

    & .text {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      line-height: 1.3;
      margin-bottom: 8px;
    }
  }

  .tick {
    color: ${ThemeDefault.blue};
    width: 16px; 
    margin-right: 8px;
    flex-shrink: 0;
    flex-grow: 0;
  }
`

const SectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};
  margin-top: 4px;

  &.m-top {
    padding-top: 0;
  }

  .addresses {
    margin-bottom: 24px;
  }

  .hero-inner {
    
    &.inner-div {
      padding: 0 !important;
      padding-bottom: 0 !important;

      & .gatsby-image-wrapper {
        height: 100%;
      }
    }

    flex-direction: row;
    display: flex;
    align-items: stretch;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.2;
      letter-spacing: -1px;
      margin-bottom: 16px;
      text-align: left;
      font-weight: 500;

      @media ${device.MXmd} {
        font-size: ${ThemeDefault.font_6}
      }
      p {
        margin-bottom: 20px;
      }
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }
    
    .spacer {
      margin-bottom: 64px;

      @media ${device.MXmd} {
        margin-bottom: 32px;
      }
    }

    .title-col {
      padding-top: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      padding-left: calc(24px + 24px);
      padding-right: calc(24px + 24px);
      padding-bottom: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      width: 60%;
      justify-content: center;
      
      @media ${device.MXmd} {
        padding: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px) 24px;
        width: 75%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
    }

    .hero-text {
      text-align: left;
      font-size: var(--font-2);
      padding-right: 64px;

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .image-col {
      width: 40%;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      text-align: left;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      
      @media ${device.MXmd} {
        width: 35%;
      }

      @media ${device.MXsm} {
        width: 100%;
      }

      @media ${device.MXxs} {
        width: 100%;
      }
      
    }

  }
  .contact-top {
    margin-bottom: 32px;
  }
  .content-col {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    & h2 {
      font-family: ${ThemeDefault.fontSecondary};
      font-size: ${ThemeDefault.font_4};
      line-height: 1.1;
      font-weight: 500;
      margin-bottom: 16px;
    }
    & h3 {
      font-family: ${ThemeDefault.fontSecondary};
      font-size: ${ThemeDefault.font_2};
      line-height: 1.1;
      font-weight: 400;
      margin-bottom: 16px;
    }
    &.sales {
      background-color: ${ThemeDefault.blueLight};
      a {
        color: ${ThemeDefault.black};
        text-decoration: underline;
        transition: 400ms;
        &:hover {
          text-decoration: none;
          color: ${ThemeDefault.black};
        }
      }
      & .button {
        font-weight: 400;
        background-color: ${ThemeDefault.black};
        color: ${ThemeDefault.white};
      }
    }
    &.support {
      background-color: ${ThemeDefault.blueLight};
      a {
        color: ${ThemeDefault.black};
        text-decoration: underline;
        transition: 400ms;
        &:hover {
          text-decoration: none;
          color: ${ThemeDefault.black};
        }
      }
      & .button {
        font-weight: 400;
        background-color: ${ThemeDefault.black};
        color: ${ThemeDefault.white};
      }
    }
  }

  
  .socials-line {
    display: flex;
    flex-direction: row;

    & svg,
    & img {
      margin-right: 8px;
      flex-shrink: 0;
      width: 32px;
    }
  }
  
`

const Contact = ( data ) => {
  
  const hubspot = useRef(null);
  const zendesk = useRef(null);

  const [formToShow, setFormToShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const setForm = (form) => {
    setFormToShow(form);
    setIsSubmitting(true);

    setTimeout(() => {

      switch (form) {
        case 1:
          hubspot.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          break;
        case 2:
          zendesk.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          break;
        default:
          break;
      }
      setIsSubmitting(false);

    }, 800)
  }

  const contactData = useContactData();
  
  // let hubData = {
  //   hubspotCode: contactData.page.contactPage.hubspotCode,
  //   title: contactData.page.contactPage.hubspotTitle,
  //   text: contactData.page.contactPage.hubspotText
  // };
  //console.log(hubData);

  const {childImageSharp, publicURL} = contactData.page.contactPage.heroImage.imageFile;

  return (
    <>
      <SectionComponent key={`hero-${data.index}`} bgColour={contactData.page.contactPage.sectionBgColour} > 
        <div className="inner-div wrapper hero-inner">
          <div className="title-col">

            { contactData.page.contactPage.title && <div className="hero-title"><h1>{ParseAndRemoveTags(contactData.page.contactPage.title)}</h1></div> }
            
          </div>
          <div className="image-col">
            {
              childImageSharp ? 
                <GatsbyImage alt={contactData.page.contactPage.altText ? contactData.page.contactPage.altText : contactData.page.contactPage.title.replace(/(<([^>]+)>)/gi, "")} image={childImageSharp.gatsbyImageData} className={contactData.page.contactPage.className} /> :
                <img alt={contactData.page.contactPage.altText ? contactData.page.contactPage.altText : contactData.page.contactPage.title.replace(/(<([^>]+)>)/gi, "")} src={publicURL} className={contactData.page.contactPage.className} /> 
            }
          </div>
        </div>
      </SectionComponent>  

      <SectionComponent > 
        <div className="inner-div">
          <div className="c-columns-8 c-columns-gap-xl c-columns-l-1">
            

            <div className="content-col sales c-col-span-3  c-columns-l-1">
              <div className="contact-top">
                <h2>{contactData.page.contactPage.hubspotTitle && ParseHTML(contactData.page.contactPage.hubspotTitle)}</h2>
                <div>{contactData.page.contactPage.hubspotText && ParseHTML(contactData.page.contactPage.hubspotText)}</div>
              </div>
              <button onClick={() => setForm(1)} className="button"><span className="button-inner">{(isSubmitting && formToShow === 1) && <Spinner /> }Contact Sales Now</span></button>
            </div>
            <div className="content-col support c-col-span-3  c-columns-l-1">   
              <div className="contact-top">
                <h2>{contactData.page.contactPage.zenDeskTitle && ParseHTML(contactData.page.contactPage.zenDeskTitle)}</h2>
                <div>{contactData.page.contactPage.zenDeskText && ParseHTML(contactData.page.contactPage.zenDeskText)}</div>
              </div>
              <button onClick={() => setForm(2)} className="button"><span className="button-inner">{(isSubmitting && formToShow === 2) && <Spinner /> }Contact Support Now</span></button>
            </div>

            <div className="content-col address c-col-span-2">
              <div className="addresses">
                <h3>Physical Address</h3>
                <div className="addy">
                  <div className="addy-line">Level 3</div>
                  <div className="addy-line">36 Grant Road</div>
                  <div className="addy-line">Queenstown</div>
                  <div className="addy-line">New Zealand</div>
                </div>
              </div>
              <div className="social">
                <div className="addy">
                  <div className="socials-line">
                    <a href="mailto:info@loadedreports.com" aria-label="Email Icon" ><Email width="24" /></a>
                    <a href={contactData.socialFields.facebook} aria-label="Facebook Icon" target="_blank" rel="noreferrer"><Facebook width="24" /></a>
                    <a href={contactData.socialFields.instagram} aria-label="Instagram Icon" target="_blank" rel="noreferrer"><Instagram width="24" /></a>
                    <a href={contactData.socialFields.linkedin} aria-label="Linked In Icon" target="_blank" rel="noreferrer"><LinkedIn width="24" /></a>
                    <a href={`https://www.twitter.com/${contactData.socialFields.twitter.replace('@', '')}`} aria-label="Twitter Icon" target="_blank" rel="noreferrer"><Twitter width="24" /></a>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </SectionComponent>  

      {formToShow === 1 && <HubspotFormSection cssClass="m-top" bgColour={ThemeDefault.blueLight} ref={hubspot} >
        <div className="inner-div">
            <div className="form-col" >
              <h2>{contactData.page.contactPage.hubspotFormTitle && ParseHTML(contactData.page.contactPage.hubspotFormTitle)}</h2>
              <div>{contactData.page.contactPage.hubspotFormText && ParseHTML(contactData.page.contactPage.hubspotFormText)}</div>
              <div className="form-wrap" >
                <HubspotForms formID={contactData.page.contactPage.hubspotCode} blockID="contact-form" blackButton="true" />
              </div>
            </div>
        </div>
      </HubspotFormSection> }
      
      {formToShow === 2 && <ZendeskForm ref={zendesk} contactData={contactData} setFormToShow={setFormToShow} bgColour="blue" /> }

      {formToShow === 3 && <SectionComponent > 
        <div className="inner-div">
          <div className="message">
            Thank you for your message, we will get back to you soon.
          </div>
        </div>
      </SectionComponent> }


    </>
  );
}

export default Contact


