import { useStaticQuery, graphql } from "gatsby"


export const useContactData = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query ContactData {
        wordpress {
          page(idType: URI, id: "contact") {
            contactPage {
              title
              sectionBgColour
              hubspotCode
              hubspotTitle
              hubspotText
              hubspotFormTitle
              hubspotFormText
              zenDeskText
              zenDeskTitle
              zenDeskFormTitle
              zenDeskFormText
              heroImage {
                altText
                srcSet
                sourceUrl
                imageFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                    fluid(maxWidth: 1200) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      presentationWidth
                    }
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
          socialFields {
            facebook
            instagram
            linkedin
            pinterest
            twitter
            youtube
          }
        }
      }
    `
  )
  return wordpress
}
