import React from "react"
import Base from "./Base"

import Contact from "../Contact/Contact";

const ContactPageTemplate = ({ pageContext }) =>  {
    
  return (
    <Base context={pageContext}>
      <Contact data={pageContext} />
    </Base>
  )
}

export default ContactPageTemplate
