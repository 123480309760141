import React from "react"
import axios from 'axios';

import styled from "styled-components"

import { useForm } from "react-hook-form";

import ThemeDefault from "../theme/ThemeDefault";

import ParseHTML from "../helpers/ParseHTML";

const ZendeskSection = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`

  background-color: ${(props) => props.bgColour};
  margin-bottom: 64px;

  text-align: center;

  .inner-div {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
  }

  #contact-form,
  .actions {
    margin: 0;
    padding: 0;
  }

  label {
    display: none !important;
  }

  .actions,
  .input {
    padding: 0 !important;
    margin: 0 !important;
  }

  input,
  textarea {
    padding: 8px !important;
    border: 1px solid ${ThemeDefault.greyDark} !important;
    border-radius: 0 !important;
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    min-height: auto !important;
    box-sizing: border-box !important;
    font-size: 16px !important;
    line-height: 1.4 !important;
    height: auto !important;
  }

  button[type="button"],
  button[type="submit"] {
    display: block;
    padding: 16px 24px  !important;
    background-color: ${ThemeDefault.black} !important;
    border: none  !important;
    box-shadow: 0 4px 6px rgba(50,50,93,0.11),0 1px 3px rgba(0,0,0,0.08)  !important;
    color: ${ThemeDefault.white}  !important;
    cursor: pointer  !important;
    font-weight: 700  !important;
    line-height: 1  !important;
    outline: none  !important;
    text-decoration: none  !important;
    transition: all 0.15s ease  !important;
    white-space: nowrap  !important;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box !important;
    text-align: center;
  }

  .form-group {
    margin-bottom: 16px !important;
    .form-control {
    }
  }

  select {
    border: 1px solid ${ThemeDefault.greyDark} !important;
    border-radius: 0 !important;
    width: 100%;
    padding: 10px 8px !important;
    max-width: 100%;
    min-height: auto !important;
    box-sizing: border-box !important;
    font-size: 16px !important;
    line-height: 1.4 !important;
    height: auto !important;
    appearance: none;

  }

  #contact-form-subject {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      right: 8px;
      top: 50%;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid ${ThemeDefault.black};
      transform: translateY(-50%);
    }
  }

  .form-col {
    margin: 0 auto;
    width: 90% !important;
    max-width: 600px !important;
    display: block !important;
  }

  .help-block {
    color: #d92a0e;
  }

`

const ZendeskForm = React.forwardRef((props, ref) => {

  const formOptions = { mode: "onBlur" };

  const { register, handleSubmit, watch, formState } = useForm(formOptions);
  const { errors } = formState;
  const watchSubject = watch("subject");

  const getMessage = (data) => {
    switch (data.subject) {
      case 'intrest':
      case 'demo':
      case 'webinar':
        return [
          `Company: ${data.companyName}`,
          `POS: ${data.telephone}`,
          `Outlets: ${data.pos}`,
          `Phone: ${data.numberOfOutlets}`,
        ].join('\n');
      case 'feedback':
      case 'other':
      default:
        return [
          `Phone: ${data.telephone}`,
          `${data.message}`,
        ].join('\n');
    }
  }

  const submitZendesk = (data) => {

    // console.log(data)
    // console.log(getMessage(data))

    const zendeskData = {
        email: data.email,
        name: data.name,
        subject: `Contact Form - ${data.subject}`,
        message: getMessage(data)
    }

    axios.post(`${process.env.GATSBY_MERCURL}/zendesk`, zendeskData)
      .then((response) => {
        //(response);
        //console.log(response.data)
        props.setFormToShow(3);
      });

  }

  return (
    <ZendeskSection cssClass="m-top" bgColour={ThemeDefault.blueLight} ref={ref} >
      <div className="inner-div">
        <div className="form-col" >
          <h2>{props.contactData.page.contactPage.zenDeskFormTitle && ParseHTML(props.contactData.page.contactPage.zenDeskFormTitle)}</h2>
          <div>{props.contactData.page.contactPage.zenDeskFormText && ParseHTML(props.contactData.page.contactPage.zenDeskFormText)}</div>
          <div className="form-wrap" >

            <form id="contact-form" onSubmit={handleSubmit(submitZendesk)}>
              <div className="form-group" id="contact-form-name">
                  <input type="text" name="name" placeholder="Full Name" className="form-control" {...register("name", { required: true })} />
                  {errors.name && <span className="help-block">Name is required</span>}
              </div>

              <div className="form-group" id="contact-form-email">
                  <input type="email" name="email" placeholder="Email Address" className="form-control" {...register("email",  { required: true, pattern: "[^@\s]+@[^@\s]+\.[^@\s]+" })} />
                  {errors.email && <span className="help-block">A valid email address is required</span>}
              </div>

              <div className="form-group" id="contact-form-phone">
                  <input type="text" className="form-control" name="telephone" placeholder="Telephone" {...register("telephone",  { required: true })} />
                  {errors.telephone && <span className="help-block">A telephone number is required</span>}
              </div>

              <div className="form-group" id="contact-form-subject">
                  <select className="form-control" {...register("subject",  { required: true })} >
                      <option value="">Please choose a reason for contacting us</option>
                      <option value="interest">I am interested in using Loaded Reports to help manage my business</option>
                      <option value="feedback">I have feedback regarding Loaded Reports</option>
                      <option value="demo">I would like to book a Loaded Reports demo</option>
                      <option value="webinar">I would like to sign-up for a Loaded Reports webinar</option>
                      <option value="other">Other</option>
                  </select>
                  {errors.subject && <span className="help-block">A subject is required</span>}
              </div>

              { (watchSubject === 'interest' || watchSubject === 'demo' || watchSubject === 'webinar') && <div className="form-group" id="contact-form-company">
                  <input type="text" className="form-control" name="companyName" placeholder="Company Name" {...register("companyName", { required: true})} disabled={!(watchSubject === 'interest' || watchSubject === 'demo' || watchSubject === 'webinar')} />
                  {errors.companyName && <span className="help-block">Company name is required</span>}
              </div>
              }

              { (watchSubject === 'interest' || watchSubject === 'demo' || watchSubject === 'webinar') && <div className="form-group" id="contact-form-pos">
                  <input type="text" className="form-control" name="POS" placeholder="Point of Sale System"  {...register("POS", { required: true})}  disabled={!(watchSubject === 'interest' || watchSubject === 'demo' || watchSubject === 'webinar')}  />
                  {errors.POS && <span className="help-block">POS is required</span>}
              </div>
              }

              { (watchSubject === 'interest' || watchSubject === 'demo' || watchSubject === 'webinar') && <div className="form-group" id="contact-form-outlets">
                  <input type="number" className="form-control" nam="interest" placeholder="Number Of Outlets"  {...register("outlets", { required: true})} disabled={!(watchSubject === 'interest' || watchSubject === 'demo' || watchSubject === 'webinar')}  />
                  {errors.outlets && <span className="help-block">Number of outlets is required</span>}
              </div>
              }

              { (watchSubject === 'feedback' || watchSubject === 'other') && <div className="form-group" id="contact-form-message">
                  <textarea rows="4" placeholder="Your Message" name="message" className="form-control" {...register("message", { required: true})} disabled={!(watchSubject === 'feedback' || watchSubject === 'other')} ></textarea>
                  {errors.message &&<span className="help-block">Message is required</span>}
              </div>
            }

              <div className="form-group text-right">
                  <button type="submit" className="" >Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ZendeskSection>
  );
});

export default ZendeskForm
